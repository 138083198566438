<template>
  <div class="home">
    <div class="head">
      <div class="leftLogo">
        <img src="../assets/headlogo (2).png" alt="" />
      </div>
      <div class="rightTag">
        <span>首页</span>
        <span>服务项目</span>
        <span>公司介绍</span>
        <span>优秀案例</span>
        <span>合作联系</span>
        <span>加入我们</span>
      </div>
    </div>
    <div class="banner">
      <img src="../assets/banner1.jpg" alt="" />
    </div>
    <div class="text">
      <h1>公司介绍</h1>
      <p>Company services</p>
    </div>
    <div class="content">
      <div class="leftC">
        <h2>关于我们</h2>
        <p>
          杭州腾昕信息技术服务有限公司（简称“杭州腾昕”）成立于2021年3月，公司办公地址位于浙江省杭州市和浙江省义乌市。我公司专职从事企业微信在政务行业的推广落地和运营服务，是企业微信在基层警务场景和网格化治理场景下的开拓者和领跑者，服务的义乌市公安局江东派出所案例是2021中国国际大数据产业博览会上警务数字化领域的典型案例。同时，本公司还拥有腾讯云未来社区等多项业务的授权服务资质。
        </p>
        <button class="btn">
          了解更多
        </button>
      </div>
      <div class="rightC">
        <img src="../assets/services.jpg" alt="" />
      </div>
    </div>
    <div class="text">
      <h1>公司服务</h1>
      <p>Company advantage</p>
    </div>
    <div class="imgslist">
      <div class="one">
        <img src="../assets/advantage1.png" alt="" />
      </div>
      <div class="one">
        <img src="../assets/advantage2.png" alt="" />
      </div>
      <div class="one">
        <img src="../assets/advantage3.png" alt="" />
      </div>
    </div>
    <div class="three">
      <div class="two">企业微信服务</div>
      <div class="two">小程序应用开发</div>
      <div class="two">腾讯云未来社区</div>
    </div>
    <div class="text">
      <h1>公司优势</h1>
      <p>Company introduction</p>
    </div>
    <div class="iind">
      <img src="../assets/222.png" alt="" />
    </div>
    <div class="three">
      <div class="two">天时</div>
      <div class="two">地利</div>
      <div class="two">人和</div>
    </div>
    <div class="text">
      <h1>服务客户</h1>
      <p>Serving custmers</p>
    </div>
    <div class="hhn">
      <img src="../assets/dds.png" alt="" />
    </div>
    <div class="sff">
      <img src="../assets/7.png" alt="" />
    </div>
  </div>  
</template>

<script>

export default {
  name: 'HomeView',
  components: {

  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.head {
  width: 100%;
  height: 5rem;
  /* border: 1px solid; */
}
.leftLogo {
  width: 46%;
  height: 5rem;
  float: left;
  vertical-align: middle;
  display: table-cell;
  text-align: center;
  /* border: 1px solid red; */
}
.leftLogo img {
  width: 100%;
  height: 100%;
}
.rightTag {
  width: 52%;
  height: 5rem;
  line-height: 5rem;
  float: right;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  /* border: 1px solid turquoise; */
}
.banner {
  width: 100%;
  height: 25rem;
}
.banner img {
  width: 100%;
  height: 100%;
}
.text {
  width: 100%;
  height: 6rem;
  color: #1e2cb2;
  text-align: center;
  /* background: pink; */
}
.text h1 {
  display: inline-block;
  padding-top: 12px;
}
.text p {
  padding-top: 10px;
}
.content {
  width: 100%;
  height: 22rem;
  display: flex;
  /* justify-content: space-between; */
  background-color: #eef0f6;
}
.leftC {
  flex: 1;
  padding-left: 20px;
}
.leftC h2 {
  display: inline-block;
  font-size: 32px;
  font-weight: 500;
  padding-top: 20px;
}
.leftC p {
  display: inline-block;
  line-height: 30px;
  padding-top: 20px;
  font-size: 18px;
}
.rightC {
  flex: 1;
  height: 22rem;
}
.rightC img {
  width: 100%;
  height: 100%;
}
.btn {
  width: 10rem;
  height: 2.2rem;
  background-color: #0052d9;
  border: 1px solid #0052d9;
  color: white;
  margin-top: 30px;
  border-radius: 10px;
}
.imgslist {
  width: 100%;
  height: 18rem;
  display: flex;
  /* border: 1px solid pink; */
}
.one {
  flex: 1;
  height: 18rem;
}
.one img {
  width: 100%;
  height: 100%;
}
.three {
  width: 100%;
  height: 5rem;
  margin-top: 20px;
  display: flex;
  /* border: 1px solid firebrick; */
}
.two {
  flex: 1;
  height: 5rem;
  border-radius: 10px;
  color: white;
  line-height: 5rem;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  background-color: #323cef;
}
.iind {
  width: 100%;
  height: 16rem;
}
.iind img {
  width: 100%;
  height: 100%;
}
.hhn {
  width: 100%;
  height: 36rem;
}
.hhn img {
  width: 100%;
  height: 100%;
}
.sff {
  width: 100%;
  height: 20rem;
}
.sff img {
  width: 100%;
  height: 100%;
}
</style>
